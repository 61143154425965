
import { computed, defineComponent } from 'vue'
// import BulkUserInfo from '@/components/pages/admin/users/BulkUserInfo.vue'
// import BulkUsersTable from '@/components/pages/admin/users/BulkUsersTable.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

export default defineComponent({
  components: {
    // BulkUserInfo,
    // BulkUsersTable,
    TmButton,
    TmModal,
  },
  props: {
    user: {
      type: Object,
    },
    users: {
      type: [Object, Array],
    },
  },
  setup(props) {
    const modalSize = computed(() => props.users?.length > 1 ? 'xLarge' : 'small')
    const title = computed(() => `Deactivate ${props.users?.length > 1 ? props.users?.length : ''} user${props.users?.length > 1 ? 's' : ''}`)
    const btnTitle = computed(() => `Deactivate ${props.users?.length > 1 ? `${props.users?.length} users` : ''}`)

    return {
      modalSize,
      title,
      btnTitle,
    }
  },
})
